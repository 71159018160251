<template>
  <div class="introduction">
    <div class="infoNews">
      <div class="title">
        <span>基本信息</span>
        <div class="download">
          <el-button :disabled="teacherDetail.coursePackageUri == ''" @click="downloadCourse"
            >下载讲师课程包</el-button
          >
          <el-button @click="lookTeacher">观看讲师课程</el-button>
        </div>
      </div>
      <div class="info">
        <div class="infoItem">
          <div class="infoItemIn">
            <span class="infoItemInBefore">讲师姓名：</span>
            <span class="infoItemInAfter">{{ teacherDetail.teacherName }}</span>
          </div>
        </div>
        <div class="infoItem">
          <div class="infoItemIn">
            <span class="infoItemInBefore">讲师性别：</span>
            <span class="infoItemInAfter">{{ filterSex(teacherDetail.gender) }}</span>
          </div>
        </div>
        <div class="infoItem">
          <div class="infoItemIn">
            <span class="infoItemInBefore">常驻地区：</span>
            <span class="infoItemInAfter"
              >{{ teacherDetail.province }}-{{ teacherDetail.city }}</span
            >
          </div>
        </div>
        <div class="infoItem">
          <div class="infoItemIn">
            <span class="infoItemInBefore">授课报价：</span>
            <span
              v-if="
                teacherDetail.lowestOffer !== '' &&
                  teacherDetail.highestOffer !== '' &&
                  teacherDetail.lowestOffer != teacherDetail.highestOffer
              "
              class="infoItemInAfter"
              >￥{{ teacherDetail.lowestOffer }}-￥{{ teacherDetail.highestOffer }}</span
            >
            <span
              v-if="teacherDetail.lowestOffer == 0 && teacherDetail.highestOffer == 0"
              class="infoItemInAfter"
              >联系客服:17786424575</span
            >
            <span
              v-if="
                teacherDetail.lowestOffer == teacherDetail.highestOffer &&
                  teacherDetail.lowestOffer != 0
              "
              class="infoItemInAfter"
              >￥{{ teacherDetail.lowestOffer }}</span
            >
          </div>
        </div>
        <div class="infoItem">
          <div class="infoItemIn">
            <span class="infoItemInBefore">讲师性质：</span>
            <span class="infoItemInAfter">{{ teacherDetail.teacherSourceStr }}</span>
          </div>
        </div>
        <div class="infoItem">
          <div class="infoItemIn">
            <span class="infoItemInBefore">讲师职称：</span>
            <span class="infoItemInAfter">{{ teacherDetail.teacherRankStr }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="teacherDetail.feature !== ''" class="peculiarity">
      <div class="title">讲师特点</div>
      <div class="textarea">{{ teacherDetail.feature }}</div>
    </div>
    <div v-if="teacherDetail.offlineCourses !== ''" class="peculiarity">
      <div class="title">主讲课程</div>
      <div class="textarea">{{ teacherDetail.offlineCourses }}</div>
    </div>
    <div v-if="teacherDetail.teacherDetails !== ''" class="peculiarity">
      <div class="title">讲师介绍</div>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="textarea" v-html="teacherDetail.teacherDetails"></div>
    </div>
    <div v-if="teacherDetail.serviceEnterprise !== ''" class="peculiarity">
      <div class="title">服务企业</div>
      <div class="textarea">{{ teacherDetail.serviceEnterprise }}</div>
    </div>
    <div v-if="teacherDetail.projects !== ''" class="peculiarity">
      <div class="title">主持项目</div>
      <div class="textarea">{{ teacherDetail.projects }}</div>
    </div>
    <div v-if="teacherDetail.publications !== ''" class="peculiarity">
      <div class="title">出版作品</div>
      <div class="textarea">{{ teacherDetail.publications }}</div>
    </div>
    <div v-if="teacherDetail.experience !== ''" class="peculiarity">
      <div class="title">工作经历</div>
      <div class="textarea">{{ teacherDetail.experience }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    teacherDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  created() {},
  methods: {
    filterSex(sex) {
      if (sex == 1) {
        return '男'
      } else {
        return '女'
      }
    },
    downloadCourse() {
      // console.log(this.teacherDetail.coursePackageUri)
      const href = this.teacherDetail.coursePackageUri
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = href
      a.click()
      URL.revokeObjectURL(a.href)
    },
    lookTeacher() {
      this.$emit('lookTeacher', 4)
    },
  },
}
</script>

<style scoped lang="scss">
.introduction {
  margin-left: 20px;
  .infoNews {
    margin-bottom: 35px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .info {
      margin-left: 20px;
      display: flex;
      flex-wrap: wrap;
      .infoItem {
        width: 33.33%;
        .infoItemIn {
          font-size: 14px;
          line-height: 38px;
          .infoItemInBefore {
            color: #909399;
            width: 80px;
            text-align: right;
            display: inline-block;
          }
          .infoItemInAfter {
            color: #333333;
          }
        }
      }
    }
  }
  .peculiarity {
    margin-bottom: 35px;
  }
  .title {
    color: #333333;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .textarea {
    white-space: pre-wrap;
    padding-left: 30px;
    color: #606266;
    line-height: 22px;
    font-size: 14px;
  }
}
</style>

<template>
  <div class="detail">
    <el-card class="box-card">
      <div class="header">
        <!-- <el-row :gutter="20" type="flex">
          <el-col :span="10"> -->
        <div class="teacherL">
          <div class="teacherImg" :style="`background-image:url(${teacherPic})`"></div>
          <div class="teacherDetail">
            <div class="teacherName">
              {{ teacherDetail.teacherName }}
              <span class="teacherRankStr">{{ teacherDetail.teacherRankStr }}</span>
            </div>
            <div class="teacterTitle">{{ teacherDetail.title }}</div>
            <div class="teacherTags">
              <span
                v-for="(item, index) in teacherDetail.teacherTags"
                :key="index"
                :style="{ color: `${item.color}`, background: `${item.background}` }"
              >
                {{ item.tagName }}
              </span>
            </div>
          </div>
        </div>
        <!-- </el-col> -->
        <!-- <el-col :span="14" class="elCol10"> -->
        <div class="teacherR">
          <div class="teacherRItem">
            <div class="city">{{ teacherDetail.province }}</div>
            <div class="city">{{ teacherDetail.city }}</div>
            <div class="region mt">常驻地区</div>
          </div>
          <div class="teacherRItem">
            <div class="price">
              <span
                v-if="
                  teacherDetail.lowestOffer !== '' &&
                    teacherDetail.highestOffer !== '' &&
                    teacherDetail.lowestOffer != teacherDetail.highestOffer
                "
                class="infoItemInAfter"
                >￥{{ teacherDetail.lowestOffer }}-￥{{ teacherDetail.highestOffer }}</span
              >
              <span
                v-if="teacherDetail.lowestOffer == 0 && teacherDetail.highestOffer == 0"
                class="infoItemInAfter"
                >暂无</span
              >
              <span
                v-if="
                  teacherDetail.lowestOffer == teacherDetail.highestOffer &&
                    teacherDetail.highestOffer != 0
                "
                class="infoItemInAfter"
                >￥{{ teacherDetail.lowestOffer }}</span
              >
            </div>
            <div class="region">授课报价</div>
          </div>
          <div class="teacherRItem">
            <div class="praise">{{ teacherDetail.favorableCommentRate }}</div>
            <div class="region">好评率</div>
          </div>
          <div class="aboutTeacherBtn" @click="handleAbout">
            <el-button type="primary">预约讲师讲课</el-button>
          </div>
        </div>
        <!-- </el-col>
        </el-row> -->
      </div>
    </el-card>

    <el-row :gutter="20" style="margin-top:30px !important" type="flex">
      <el-col :span="18">
        <el-card class="card">
          <div slot="header" class="clearfix">
            <div class="teacherTabsWrap">
              <div class="teacherTabs">
                <div
                  v-for="(item, index) in titleArr"
                  :key="index"
                  class="title"
                  :class="{ activeSpace: index == currentIndex }"
                  @click="clickTabs(index)"
                >
                  {{ item }}
                  <div class="space"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="tabsContent">
            <Introduction
              v-if="currentIndex == 0"
              :teacher-detail="teacherDetail"
              @lookTeacher="lookTeacher"
            ></Introduction>
            <CommonList
              v-if="currentIndex == 1"
              :current-index="currentIndex"
              :teacher-id="this.$route.query.id"
            ></CommonList>
            <CommonList
              v-if="currentIndex == 2"
              :current-index="currentIndex"
              :teacher-id="this.$route.query.id"
            ></CommonList>
            <CommonList
              v-if="currentIndex == 3"
              :current-index="currentIndex"
              :teacher-id="this.$route.query.id"
            ></CommonList>
            <CommonList
              v-if="currentIndex == 4"
              :current-index="currentIndex"
              :teacher-id="this.$route.query.id"
            ></CommonList>
            <Rate
              v-if="currentIndex == 5"
              :teacher-id="this.$route.query.id"
              :current-index="currentIndex"
            ></Rate>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <div>
          <GoSchool v-if="currentIndex == 0" :organizationdetail="organizationdetail"></GoSchool>
          <RightCourse
            v-if="
              currentIndex == 0 ||
                currentIndex == 1 ||
                currentIndex == 3 ||
                currentIndex == 4 ||
                currentIndex == 5
            "
            :status="'on'"
            :current-index="currentIndex"
            :teacher-name="teacherDetail.teacherName"
            :teacher-id="this.$route.query.id"
          ></RightCourse>
          <RightCourse
            v-if="
              currentIndex == 0 ||
                currentIndex == 2 ||
                currentIndex == 3 ||
                currentIndex == 4 ||
                currentIndex == 5
            "
            :status="'off'"
            :current-index="currentIndex"
            :teacher-id="this.$route.query.id"
          ></RightCourse>
          <CourseWitness
            v-if="currentIndex == 0 || currentIndex == 1 || currentIndex == 2"
            :current-index="currentIndex"
            :teacher-id="this.$route.query.id"
          ></CourseWitness>
        </div>
      </el-col>
    </el-row>
    <Dialog
      :teacher-id="Number(this.$route.query.id)"
      :dialog-title="dialogTitle"
      :info-status="infoStatus"
      :dialog-visible="dialogVisible"
      :btn-text="btnText"
      @handleClose="handleClose"
      @closeInfo="closeInfo"
      @success="geteCourseDetailData()"
    ></Dialog>
  </div>
</template>

<script>
import Rate from './components/Rate.vue'
import CourseWitness from './components/CourseWitness.vue'
import RightCourse from './components/RightCourse.vue'
import Introduction from './components/Introduction.vue'
import CommonList from './components/CommonList.vue'
import GoSchool from './components/GoSchool.vue'
import to from 'await-to'
import { validateInputMobile } from '@/utils/validate'
import Dialog from '../components/dialog'
import {
  geteTacherDetail,
  geteCourseDetail,
  geteOrganizationdetail,
  excellentTeachers,
} from '@/api/teacher'
export default {
  components: {
    Dialog,
    Introduction,
    CommonList,
    GoSchool,
    RightCourse,
    CourseWitness,
    Rate,
  },
  data() {
    return {
      titleArr: ['讲师简介', '线下课程', '线上课程', '授课见证', '授课视频', '客户评价'],
      currentIndex: 0,
      activeName: 'first',
      organizationId: this.$route.query.organizationId,
      pager: {
        size: 8,
        current: 1,
        id: this.$route.query.id,
      },
      total: 0,
      loading: 0,
      dialogVisible: false,
      infoStatus: true,
      formDetail: {
        reserveType: '',
        reserveTime: '',
        studentCount: '',
        costBudget: '',
        contactName: '',
        contactPhone: '',
        annexUrlList: [],
        teacherId: '',
      },
      fileList: [],
      paramsFileList: [],
      rules: {
        reserveType: [{ required: true, message: '请选择预约类型', trigger: 'change' }],
        studentCount: [{ required: true, message: '请输入预约人数', trigger: 'blur' }],
        costBudget: [{ required: true, message: '请选择预估费用', trigger: 'change' }],
        contactName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        reserveTime: [{ required: true, message: '请选择预约时间', trigger: 'change' }],
        contactPhone: [{ required: true, validator: validateInputMobile, trigger: 'blur' }],
      },
      teacherDetail: {},
      organizationdetail: {},
      courseList: [],
      teacherPic: '',
      dialogTitle: '提交预约信息',
      btnText: '提交信息',
      excellentTeachersList: [],
    }
  },
  created() {
    this.geteTacherDetailData()
    this.geteCourseDetailData()
    this.geteOrganizationdetailData()
  },
  methods: {
    async geteTacherDetailData() {
      const [res, err] = await to(
        geteTacherDetail({ id: this.pager.id, organizationId: this.organizationId }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.teacherDetail = res.data
      if (this.teacherDetail.teacherPics.length !== 0) {
        this.teacherPic = this.teacherDetail.teacherPics[0]
      }
      this.excellentTeachersData()
    },
    async geteCourseDetailData() {
      this.loading = true
      const [res, err] = await to(geteCourseDetail(this.pager))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.courseList = res.data.list
      this.total = res.data.total
      this.loading = false
    },
    async geteOrganizationdetailData() {
      const [res, err] = await to(geteOrganizationdetail({ organizationId: this.organizationId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.organizationdetail = res.data
    },
    async excellentTeachersData() {
      const { teacherMajors, teacherId } = this.teacherDetail
      let arr = []
      teacherMajors.map(v => arr.push(Number(v)))
      const [res, err] = await to(excellentTeachers({ teacherMajorIds: arr, teacherId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.excellentTeachersList = res.data
    },
    excellentTeachersDetail(item) {
      this.$router.push({
        path: '/teacher/detail',
        query: { organizationId: item.organizationId, id: item.teacherId },
      })
    },
    clickTabs(index) {
      this.currentIndex = index
    },
    lookTeacher(val) {
      this.currentIndex = val
    },
    //预约讲师
    handleAbout() {
      this.dialogVisible = true
      this.infoStatus = true
    },
    closeInfo() {
      this.infoStatus = false
    },
    handleClose() {
      this.dialogVisible = false
    },
    // toSchool() {
    //   this.$router.push({ path: '/teacher/school', query: { organizationId: this.organizationId } })
    // },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.geteCourseDetailData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.geteCourseDetailData()
    },
  },
}
</script>

<style scoped lang="scss">
.detail {
  .header {
    display: flex;
    .teacherL {
      display: flex;
      width: calc(100% - 720px);
      .teacherImg {
        width: 147px;
        height: 147px;
        background-size: cover;
        background-position: top center;
      }
      .teacherDetail {
        width: calc(100% - 147px);
        margin-left: 15px;
        .teacherName {
          color: #333333;
          font-weight: 500;
          font-size: 18px;
        }
        .teacherRankStr {
          color: #ff7b33;
          font-size: 12px;
          padding: 2px 4px;
          border: 1px solid #ff7b33;
          border-radius: 2px;
        }
        .teacterTitle {
          color: #333333;
          font-size: 14px;
          margin-top: 16px;
          margin-bottom: 26px;
        }
        .teacherTags {
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; //控制行数
          overflow: hidden;
          font-size: 12px;
          span {
            display: inline-block;
            padding: 0px 12px;
            line-height: 22px;
            margin: 8px 10px;
            // &:first-child {
            //   margin-left: 0;
            // }
          }
        }
      }
    }
    .mt {
      margin-top: 17px !important;
    }
    .teacherR {
      display: flex;
      width: 720px;
      justify-content: flex-end;
      .teacherRItem {
        &:last-child {
          border-right: none;
        }
        text-align: center;
        padding: 0 30px;
        border-right: 1px solid #e2e2e2;
        .city {
          color: #508afd;
          font-size: 20px;
        }
        .region {
          font-size: 14px;
          color: #909399;
          margin-top: 47px;
        }
        .price {
          color: #fc5252;
          font-size: 20px;
          font-weight: 500;
        }
        .praise {
          color: #04d4dc;
          font-size: 20px;
          font-weight: 500;
        }
      }
      .aboutTeacherBtn {
        display: flex;
        align-items: center;
        margin-left: 55px;
      }
    }
    // .elCol10 {
    //   display: flex;
    //   align-items: center;
    //   justify-content: flex-end;
    //   .mt {
    //     margin-top: 17px !important;
    //   }
    //   .teacherR {
    //     display: flex;
    //     .teacherRItem {
    //       &:last-child {
    //         border-right: none;
    //       }
    //       text-align: center;
    //       padding: 0 30px;
    //       border-right: 1px solid #e2e2e2;
    //       .city {
    //         color: #508afd;
    //         font-size: 20px;
    //       }
    //       .region {
    //         font-size: 14px;
    //         color: #909399;
    //         margin-top: 47px;
    //       }
    //       .price {
    //         color: #fc5252;
    //         font-size: 20px;
    //         font-weight: 500;
    //       }
    //       .praise {
    //         color: #04d4dc;
    //         font-size: 20px;
    //         font-weight: 500;
    //       }
    //     }
    //     .aboutTeacherBtn {
    //       display: flex;
    //       align-items: center;
    //       margin-left: 65px;
    //     }
    //   }
    // }
  }
  .card {
    ::v-deep.el-card__header {
      padding: 0 20px;
    }
    .teacherTabsWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .teacherTabs {
        display: flex;
        .title {
          &:first-child {
            margin-left: 0px;
          }
          margin-left: 47px;
          position: relative;
          line-height: 60px;
          cursor: pointer;
          .space {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 40px;
            height: 4px;
            background-color: #ff7b33;
            display: none;
          }
        }
        .activeSpace {
          color: #ff7b33 !important;
          .space {
            display: block !important;
          }
        }
      }
    }
  }
  .evaluate {
    .text {
      color: #909399;
      font-size: 14px;
      margin-top: 23px;
    }
    .evaluateItem {
      padding: 24px 10px;
      border-bottom: 1px solid #e2e2e2;
      .evaluateDetail {
        display: flex;
        align-items: center;
        .userImg {
          width: 40px;
          height: 40px;
          img {
            border-radius: 50%;
          }
        }
        .right {
          margin-left: 10px;
          .name {
            display: flex;
            .consumerName {
              margin-right: 11px;
              font-size: 14px;
              color: #666666;
            }
          }
          .time {
            font-size: 12px;
            color: #999999;
          }
        }
      }
      .footer {
        margin-top: 25px;
        width: 60%;
        color: #3e3e3e;
        font-size: 14px;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>

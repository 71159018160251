<template>
  <div class="witness">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title">授课见证</span>
      </div>
      <div v-if="list.length">
        <div v-for="(item, index) in list" :key="index" class="witnessItem" @click="toDetail(item)">
          <div class="left">
            <div class="index">0{{ index + 1 }}</div>
          </div>
          <div class="right">
            <div class="title">{{ item.teachingWitnessName }}</div>
            <div class="time">{{ item.createTime }}</div>
          </div>
        </div>
      </div>
      <div v-else class="empty">
        <div class="emptyimg"><img src="../../../../assets/teacher/empty.png" alt="" /></div>
        <div class="text">暂无数据</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import to from 'await-to'
import { witnessList } from '@/api/teacher'
export default {
  props: {
    currentIndex: {
      type: Number,
      default: 0,
    },
    teacherId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pager: {
        size: 5,
        current: 1,
        total: 0,
        teacherId: this.teacherId,
      },
      list: [],
    }
  },
  watch: {
    currentIndex: {
      handler(val) {
        console.log(val)
        if (val == 0 || val == 1 || val == 2) {
          this.witnessListData()
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  methods: {
    toDetail(item) {
      this.$router.push({
        path: '/teacher/witnessDetail',
        query: { teachingWitnessId: item.teachingWitnessId },
      })
    },
    async witnessListData() {
      const [res, err] = await to(witnessList({ ...this.pager }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.list = res.data.list
    },
  },
}
</script>

<style scoped lang="scss">
.witness {
  ::v-deep .el-card__body {
    padding: 0;
  }
  margin-top: 30px !important;
  .witnessItem {
    display: flex;
    padding: 16px 20px;
    cursor: pointer;
    .left {
      font-size: 16px;
      .index {
        color: #909399;
      }
    }
    .right {
      flex: 1;
      margin-left: 10px;
      .title {
        color: #333;
        font-size: 16px;
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .time {
        color: #909399;
        margin-top: 14px;
        font-size: 14px;
      }
    }
  }
  .empty {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .emptyimg {
      width: 100px;
      height: 80px;
    }
    .text {
      font-size: 16px;
      color: #909399;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>

<template>
  <div class="list">
    <div v-if="!emptyStatus">
      <div v-if="currentIndex == 1 || currentIndex == 2" class="text">
        共{{ pager.total }}门课程
      </div>
      <div v-if="currentIndex == 3" class="text">共{{ pager.total }}个见证</div>
      <div v-if="currentIndex == 4" class="text">共{{ pager.total }}个视频</div>
    </div>
    <div v-if="emptyStatus" class="emptyCourse">
      <span v-if="currentIndex == 1"> 当前讲师暂无线下课程，为您推荐以下线下课程:</span>
      <span v-if="currentIndex == 2"> 当前讲师暂无线上课程，为您推荐以下线上课程:</span>
      <span v-if="currentIndex == 3"> 当前讲师暂无授课见证，为您推荐以下授课见证:</span>
      <span v-if="currentIndex == 4"> 当前讲师暂无授课视频，为您推荐以下授课视频:</span>
    </div>
    <div class="listWrap">
      <div v-for="(item, index) in list" :key="index" class="listItem" @click="toDetail(item)">
        <div class="itemL">
          <img v-if="currentIndex == 1" :src="item.coursePic" alt="" />
          <img v-if="currentIndex == 2" :src="item.pic" alt="" />
          <img v-if="currentIndex == 3" :src="item.teachingWitnessPic" alt="" />
          <img v-if="currentIndex == 4" :src="item.videoPic" alt="" />
        </div>
        <div class="itemR">
          <div class="title">
            <span v-if="currentIndex == 1">{{ item.courseName }}</span>
            <span v-if="currentIndex == 2">{{ item.name }}</span>
            <span v-if="currentIndex == 3">{{ item.teachingWitnessName }}</span>
            <span v-if="currentIndex == 4">{{ item.videoName }}</span>
          </div>
          <div class="content">
            <span v-if="currentIndex == 1">{{ item.courseDesc }}</span>
            <span v-if="currentIndex == 2">{{ filterHTMLTag(item.description) }}</span>
            <span v-if="currentIndex == 4">{{ filterHTMLTag(item.videoName) }}</span>
          </div>
          <div class="footer">
            <div v-if="currentIndex == 1" class="footerText">
              <span>课程时长：{{ item.courseDuration }}天</span>
              <span class="ml">授课讲师：{{ item.teacherName }}</span>
            </div>
            <div v-if="currentIndex == 2" class="footerText">
              <span>{{ item.sectionCount }}节 | {{ item.duration }}分钟 | 融e学</span>
              <span class="ml">{{ item.studentCount }}人报名</span>
            </div>
            <div v-if="currentIndex == 2" class="price">￥{{ item.price.toFixed(2) }}</div>
            <div v-if="currentIndex == 3" class="footerText footerText3">
              <span class="icon">
                <img src="../../../../assets/teacher/time.png" alt="" />
                {{ item.createTime }}
              </span>
              <span class="ml icon">
                <img src="../../../../assets/teacher/eye.png" alt="" />{{ item.pageViews }}
              </span>
            </div>
            <div v-if="currentIndex == 4" class="footerText footerText3">
              <span class="icon">
                <img src="../../../../assets/teacher/time.png" alt="" />
                {{ item.createTime }}
              </span>
              <span class="ml icon">
                <img src="../../../../assets/teacher/eye.png" alt="" />{{ item.pageViews }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      v-if="!emptyStatus"
      class="pagination"
      :current-page.sync="pager.current"
      background
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pager.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <LookVideo ref="LookVideo"></LookVideo>
  </div>
</template>

<script>
import LookVideo from './LookVideo.vue'
import to from 'await-to'
import {
  offCourse,
  geteCourseDetail,
  witnessList,
  courseVideo,
  pushOffCourse,
  pushOnCourse,
  pushWitnessList,
  pushVideo,
} from '@/api/teacher'
export default {
  components: {
    LookVideo,
  },
  props: {
    currentIndex: {
      type: Number,
      default: 0,
    },
    teacherId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pager: {
        size: 10,
        current: 1,
        total: 0,
        teacherId: this.teacherId,
      },
      list: [],
      videoDetail: {},
      emptyStatus: null,
    }
  },
  watch: {
    currentIndex: {
      handler(val) {
        this.emptyStatus = null
        // console.log(val)
        if (val == 1) {
          this.offlineCourse()
        } else if (val == 2) {
          this.onCourseDetailData()
        } else if (val == 3) {
          this.witnessListData()
        } else if (val == 4) {
          this.courseVideoData()
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  methods: {
    //线上课程
    async onCourseDetailData() {
      const [res, err] = await to(geteCourseDetail({ ...this.pager, id: this.pager.teacherId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.list = res.data.list
      this.pager.total = res.data.total
      if (this.list.length == 0) {
        this.pushOnCourseData()
        this.emptyStatus = true
      }
    },
    //线下课程
    async offlineCourse() {
      const [res, err] = await to(offCourse({ ...this.pager }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.list = res.data.list
      this.pager.total = res.data.total
      if (this.list.length == 0) {
        this.pushOffCourseData()
        this.emptyStatus = true
      }
    },
    //授课见证
    async witnessListData() {
      const [res, err] = await to(witnessList({ ...this.pager }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.list = res.data.list
      this.pager.total = res.data.total
      if (this.list.length == 0) {
        this.pushWitnessListData()
        this.emptyStatus = true
      }
    },
    //授课视频
    async courseVideoData() {
      const [res, err] = await to(courseVideo({ ...this.pager }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.list = res.data.list
      this.pager.total = res.data.total
      if (this.list.length == 0) {
        this.pushVideoData()
        this.emptyStatus = true
      }
    },
    //推荐线下
    async pushOffCourseData() {
      const [res, err] = await to(pushOffCourse({ ...this.pager }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.list = res.data
    },
    //推荐线上
    async pushOnCourseData() {
      const [res, err] = await to(pushOnCourse({ ...this.pager }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.list = res.data
    },
    //推荐授课见证
    async pushWitnessListData() {
      const [res, err] = await to(pushWitnessList({ ...this.pager }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.list = res.data
    },
    //推荐授课视频
    async pushVideoData() {
      const [res, err] = await to(pushVideo({ ...this.pager }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.list = res.data
    },
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      if (this.currentIndex == 1) {
        this.offlineCourse()
      } else if (this.currentIndex == 2) {
        this.onCourseDetailData()
      } else if (this.currentIndex == 3) {
        this.witnessListData()
      } else if (this.currentIndex == 4) {
        this.courseVideoData()
      }
    },
    handleCurrentChange(current) {
      this.pager.current = current
      if (this.currentIndex == 1) {
        this.offlineCourse()
      } else if (this.currentIndex == 2) {
        this.onCourseDetailData()
      } else if (this.currentIndex == 3) {
        this.witnessListData()
      } else if (this.currentIndex == 4) {
        this.courseVideoData()
      }
    },
    toDetail(item) {
      if (this.currentIndex == 1) {
        this.$router.push({
          path: '/teacher/courseDetail',
          query: { offlineCourseId: item.offlineCourseId, teacherId: this.teacherId },
        })
      } else if (this.currentIndex == 2) {
        this.$router.push(`/course/shop/${item.productId}`)
      } else if (this.currentIndex == 3) {
        this.$router.push({
          path: '/teacher/witnessDetail',
          query: { teachingWitnessId: item.teachingWitnessId },
        })
      } else if (this.currentIndex == 4) {
        this.$refs.LookVideo.show(item)
      }
    },
    filterHTMLTag(content) {
      if (content) {
        var msg = content.replace(/<\/?[^>]*>/g, '')
        msg = msg.replace(/[|]*\n/, '') //去除行尾空格
        msg = msg.replace(/&nbsp;/gi, '') //去掉npsp
      } else {
        msg = ''
      }
      return msg
    },
  },
}
</script>

<style scoped lang="scss">
.list {
  .text {
    color: #909399;
    font-size: 14px;
    margin-top: 23px;
  }
  .listWrap {
    .listItem {
      cursor: pointer;
      display: flex;
      padding: 30px 0;
      .itemL {
        width: 282px;
        height: 145px;
      }
      .itemR {
        padding-left: 17px;
        padding-right: 15%;
        flex: 1;
        .title {
          font-size: 18px;
          color: #3e3e3e;
          line-height: 32px;
          margin-bottom: 8px;
        }
        .content {
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          color: #909399;
          line-height: 20px;
          font-size: 14px;
        }
        .footer {
          .footerText {
            color: #909399;
            font-size: 14px;
            margin-top: 35px;
          }
          .footerText3 {
            display: flex;
            .icon {
              display: flex;
              align-items: center;
              img {
                width: 15px;
                height: 15px;
                margin-right: 5px;
              }
            }
          }
          .price {
            color: #ff583e;
            font-size: 20px;
          }
        }
      }
    }
  }
  .pagination {
    margin-top: 20px;
  }
  .ml {
    margin-left: 41px;
  }
  .emptyCourse {
    font-size: 16px;
    color: #ff7b33;
    text-align: center;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>

<template>
  <div class="School">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title">所属学堂</span>
      </div>
      <div class="GoSchool">
        <div class="left">
          <div class="logo"><img :src="organizationdetail.pic" alt="" /></div>
          <div class="name">{{ organizationdetail.organizationName }}</div>
          <!-- <el-button type="primary" @click="toSchool">进入学堂</el-button> -->
        </div>
        <div class="right">{{ organizationdetail.introduction }}</div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    organizationdetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  created() {},
  methods: {
    toSchool() {
      this.$router.push({
        path: '/teacher/school',
        query: { organizationId: this.organizationdetail.organizationId },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.School {
  ::v-deep .el-card__body {
    padding: 20px 13px;
  }
  ::v-deep .el-card__header {
    height: 60px;
  }
  .GoSchool {
    // display: flex;
    background-color: #fff;
    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .logo {
        width: 78px;
        height: 78px;
      }
      .name {
        color: #606266;
        font-size: 20px;
        margin-top: 13px;
        margin-bottom: 19px;
      }
    }
    .right {
      // margin-left: 19px;
      text-align: center;
      color: #3e3e3e;
      line-height: 25px;
      font-size: 14px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<template>
  <div>
    <el-dialog
      title="讲师视频"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
      :before-close="handleClose"
      class="aboutsNews"
    >
      <div id="prism-player" class="prism-player"></div>
    </el-dialog>
  </div>
</template>

<script>
import to from 'await-to'
import { videoSearch, videoBrowse } from '@/api/teacher'
var player
export default {
  data() {
    return {
      dialogVisible: false,
      videoUrl: '',
    }
  },
  created() {},
  methods: {
    show(item) {
      this.dialogVisible = true
      this.$nextTick(() => {
        // eslint-disable-next-line no-undef
        player = new Aliplayer({
          id: 'prism-player',
          width: '100%',
          height: '500px',
          autoplay: true,
          isLive: false,
          rePlay: true,
          controlBarVisibility: 'hover',
        })
      })
      this.videoSearchData(item)
    },
    async videoSearchData(item) {
      await this.videoBrowseData(item)
      const { videoId, videoPlatform } = item
      const [res, err] = await to(videoSearch({ videoId, videoPlatform }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.videoUrl = res.data.videoUrl
      if (player && this.videoUrl !== '') {
        player.loadByUrl(res.data.videoUrl)
        return
      }
    },
    async videoBrowseData(item) {
      const [, err] = await to(videoBrowse({ teachingVideoId: item.teachingVideoId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
    },
    handleClose() {
      player.dispose()
      this.dialogVisible = false
    },
  },
}
</script>

<style scoped lang="scss"></style>

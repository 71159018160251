<template>
  <div class="evaluate">
    <div v-if="evaluateList.length">
      <div class="text">共{{ pager.total }}条评价</div>
      <div class="evaluateWrap">
        <div v-for="item in evaluateList" :key="item.teacherCommentId" class="evaluateItem">
          <div class="evaluateDetail">
            <div class="left">
              <div class="userImg"><img :src="item.consumerPic" alt="" /></div>
            </div>
            <div class="right">
              <div class="name">
                <div class="consumerName">{{ item.consumerName }}</div>
                <el-rate v-model="item.teacherStar" disabled text-color="#ff9900"> </el-rate>
              </div>
              <div class="time">{{ item.createTime }}</div>
            </div>
          </div>
          <div class="footer">{{ item.teacherContent }}</div>
        </div>
        <el-pagination
          class="pagination"
          :current-page.sync="pager.current"
          background
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pager.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <div v-else class="empty">
      <div class="emptyimg"><img src="../../../../assets/teacher/empty.png" alt="" /></div>
      <div class="text">暂无数据</div>
    </div>
  </div>
</template>

<script>
import to from 'await-to'
import { teacherCustomer } from '@/api/teacher'

export default {
  props: {
    currentIndex: {
      type: Number,
      default: 0,
    },
    teacherId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      evaluateList: [],
      pager: {
        size: 10,
        current: 1,
        teacherId: this.teacherId,
        total: 0,
      },
    }
  },
  watch: {
    currentIndex: {
      handler(val) {
        // console.log(val)
        if (val == 5) {
          this.teacherCustomerData()
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  methods: {
    async teacherCustomerData() {
      const [res, err] = await to(teacherCustomer({ ...this.pager }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.evaluateList = res.data.list
      this.pager.total = res.data.total
    },
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.teacherCustomerData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.teacherCustomerData()
    },
  },
}
</script>

<style scoped lang="scss">
.evaluate {
  .text {
    color: #909399;
    font-size: 14px;
    margin-top: 23px;
  }
  .evaluateItem {
    padding: 24px 10px;
    border-bottom: 1px solid #e2e2e2;
    .evaluateDetail {
      display: flex;
      align-items: center;
      .userImg {
        width: 40px;
        height: 40px;
        img {
          border-radius: 50%;
        }
      }
      .right {
        margin-left: 10px;
        .name {
          display: flex;
          .consumerName {
            margin-right: 11px;
            font-size: 14px;
            color: #666666;
          }
        }
        .time {
          font-size: 12px;
          color: #999999;
        }
      }
    }
    .footer {
      margin-top: 25px;
      width: 60%;
      color: #3e3e3e;
      font-size: 14px;
    }
  }
  .pagination {
    margin-top: 20px;
  }
  .empty {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .emptyimg {
      width: 100px;
      height: 80px;
    }
    .text {
      font-size: 16px;
      color: #909399;
    }
  }
}
img {
  width: 100%;
  height: 100%;
}
</style>
